import React, { useState, createContext, useMemo, useContext } from 'react';

interface IAuthContext {
  isAuthenticated: boolean;
  login: () => Promise<void>;
  logout: () => void;
}

export const AuthContext = createContext<IAuthContext | undefined>(undefined);

interface AuthProviderProps {
    children: React.ReactNode;
  }

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  const isDevelopment = process.env.NODE_ENV === 'development';

  const login = async () => {
    if(isDevelopment){
      setIsAuthenticated(true);
      return;
    }
  }

  const logout = async () => {
    setIsAuthenticated(false);
  }

  const contextValue : IAuthContext = useMemo<IAuthContext>(
    () => ({
      isAuthenticated,
      login,
      logout,
    }),
    [isAuthenticated] 
  );

  return (
      <AuthContext.Provider value={contextValue}>
        {children}
      </AuthContext.Provider>
  );
};

export const useAuth = (): IAuthContext => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

// function AuthProvider({ user, status, forgotPwLink, children }){
//   const memoizedValue = React.useMemo(() => {
//     return {
//       user,
//       status,
//       forgotPwLink,
//     };
//   }, [user, status, forgotPwLink]);
//   return (
//     <AuthContext.Provider value={memoizedValue}>
//       {children}
//     </AuthContext.Provider>
//   );
// }