import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import enTranslation from './locales/en/en.json';
import plTranslation from './locales/pl/pl.json'; 
import deTranslation from './locales/de/de.json'; 

const savedLanguage = localStorage.getItem('appLanguage');

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: enTranslation,
      },
      pl: {
        translation: plTranslation,
      },
      de: {
        translation: deTranslation,
      },
    },
    lng: savedLanguage || 'pl',
    fallbackLng: 'en', 
  });

export default i18n;