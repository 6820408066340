import { Suspense, lazy, useContext, useState } from 'react';
import { AuthContext } from './Authentication/AuthProvider';
import './App.scss';

export default function App() {
  const { isAuthenticated } = useAuth();
  // eslint-disable-next-line
  const [darkTheme, setDarkTheme] = useState(false);
  const LazyLandingPage = lazy(() => import('./landingPage/LandingPage'));
  const LazyCore = lazy(() => import('./pages/core/Core'));

  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker.register('/service-worker.js').then(registration => {
        console.log('SW registered: ', registration);
      }).catch(registrationError => {
        console.log('SW registration failed: ', registrationError);
      });
    });
  }

  return (
    <div className={darkTheme ? 'dark-theme' : 'light-theme'}>
      {isAuthenticated 
        ? <Suspense fallback={<p>Loading...</p>}>
            <LazyCore />
          </Suspense> 
        : <Suspense fallback={<p>Loading...</p>}>
            <LazyLandingPage />
          </Suspense> }
    </div>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
}